import React from 'react';
import PropTypes from 'react-proptypes';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import 'prismjs/themes/prism.css';
import omit from 'lodash.omit';
import { translate } from 'react-polyglot';
import LazyLoad from 'react-lazyload';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import withSetLocale from './i18n/withSetLocale';
import AppBar from './AppBar/index';
import TopBar from './TopBar';
import MaxWidthContainer from './MaxWidthContainer';
import Authorship from './Blog/Authorship';
import BlogTagList from './Blog/BlogTagList';
import SharePost from './Blog/SharePost';
import AuthorCard from './Blog/AuthorCard';
import { GhostLinkButton } from './Button';
import Disqus from './Disqus';
import Footer from './Footer';
import HeaderImage from './HeaderImage';
import ShortCodes from './ShortCodes';

import './ShortCodes/newsletter.css';
import 'katex/dist/katex.min.css';

const BlogMaxWidthContainer = styled(MaxWidthContainer)`
    padding: 0;
`;

const BlogContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    background-color: white;
`;

const MetaContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin-top: 0;
`;

const PostTitle = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 3.75rem;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.headlinesColor};
    text-align: center;
    max-width: 1024px;
    padding: 5px;
    @media (max-width: 600px) {
        font-size: 1.625rem;
        line-height: 2.1rem;
    }
`;

const PostBody = styled.div`
    font-size: 1rem;
    line-height: 1.625rem;
    @media (max-width: 740px) {
        padding: 0 10px;
    }
    @media (max-width: 600px) {
        font-size: 0.8125rem;
        line-height: 1.32rem;
    }
    > * {
        max-width: 720px;
        width: 100%;
        word-wrap: break-word;
    }
    > :first-child {
        margin-top: 0;
    }
    > .blue-box {
        background-color: aliceblue;
        padding: 1em;
        margin: 1.6rem 0;
        width: calc(100% - 2em);
    }
    > .grid {
        display: flex;
        justify-content: center;
        margin: auto;
        > div {
            margin: 5px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    > .grid {
        display: flex;
        justify-content: center;
        margin: auto;

        > div {
            margin: 5px;
            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    > .tips {
        &:before {
            margin-top: 2rem;
            content: 'Tip';
            display: block;
            margin-left: -1.5rem;
            padding-left: 1.625rem;
            font-size: 1.125rem;
            font-weight: 600;
            font-family: 'Montserrat', sans-serif;
            color: ${({ theme }) => theme.orange};
            margin-bottom: 0.625rem;
            background: url('/images/tips.svg');
            background-position-y: 50%;
            background-repeat: no-repeat;
        }
        padding: 0 20px;
        width: auto;
        > :first-child {
            margin-top: 0;
        }
    }
    > blockquote {
        padding-left: 2.5rem;
        margin-left: 0;
        width: auto;
        font-size: 1.125rem;
        line-height: 1.875rem;
        border-left: ${({ theme }) => theme.blueDark} 3px solid;
        font-style: italic;
        @media (max-width: 600px) {
            padding-left: 0.625rem;
            font-size: 0.8125rem;
            line-height: 1.32rem;
        }
    }
    * iframe,
    > iframe {
        max-width: 720px;
        width: 100%;
        @media (max-width: 600px) {
            height: auto;
        }
    }

    * a,
    > a,
    a > code {
        color: ${({ theme }) => theme.blue};
    }
    > figure {
        margin: 0;
        max-width: 720px;
    }
    figure img {
        margin-bottom: 0.25rem;
    }
    figcaption {
        text-align: center;
    }
    * img,
    > img {
        max-width: 100%;
        height: auto;
        width: auto;
        display: block;
        @media (min-width: 601px) {
            &.very-small {
                display: block;
                width: 150px;
            }
            &.small {
                display: block;
                width: 300px;
            }
            &.medium {
                display: block;
                width: 60%;
            }
            &.big {
                display: block;
                width: 90%;
            }
            &.responsive {
                max-width: 100%;
            }
        }
        @media (max-width: 600px) {
            max-height: 100%;
        }
    }
    > pre {
        border: 1px solid #dcdcdc;
        padding: 40px;
        overflow: auto;
    }
    & code {
        font-size: 0.8em !important;
        line-height: 1.2 !important;
        overflow: auto;
        > .operator {
            background: inherit;
        }
        & .gatsby-highlight-code-line {
            background-color: antiquewhite;
            display: block;
            margin-right: -1em;
            margin-left: -1em;
            padding-right: 1em;
            padding-left: 0.75em;
            border-left: 0.25em solid #ffa7c4;
            line-height: 1.7;
        }
    }
    & pre[class*='language-'] {
        line-height: 1.35;
    }
    > h2 {
        font-size: 18px;
        line-height: 1.625rem;
        color: ${({ theme }) => theme.blueDark};
        font-family: 'Montserrat', sans-serif;
        padding-top: 6rem;
        margin-top: -5rem;

        @media (max-width: 600px) {
            padding-top: 4rem;
            margin-top: -3.125rem;
        }
    }
    > h3 {
        font-size: 16px;
        line-height: 1.625rem;
        font-family: 'Montserrat', sans-serif;
    }

    > ul,
    ol {
        padding-left: 40px;
        @media (max-width: 600px) {
            padding-left: 1.25rem;
            line-height: 1.32rem;
            width: auto;
        }
        > li {
            padding-right: 40px;
            line-height: 1.625rem;
            margin-top: 1rem;
        }
    }
    > p {
        margin: 1.625rem 0;
    }
    & .table_custom table {
        margin: auto;
        width: auto;
        border: 1px solid lightgrey;
        padding: 0.5em;
        border-radius: 0.5em;
        th {
            padding: 0 1em 0.5em 0;
        }
        td {
            padding: 0 1em 0 0;
        }
    }
`;

const MetaData = styled.div`
    display: flex;
    padding: 5px;
    @media (max-width: 600px) {
        flex-direction: column;
        margin-top: 0.375rem;
    }
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 3.125rem;
    margin-bottom: 1.563rem;
    > *:last-child {
        margin-top: 1.125rem;
        @media (min-width: 600px) {
            margin-left: auto;
            margin-right: 0;
        }
        line-height: 0.5em;
    }
`;

const SectionTitle = styled.h2`
    font-size: 1.75rem;
    font-weight: 300;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    color: ${({ theme }) => theme.blue};
    margin: 4.375rem 0 2.5rem;
    @media (max-width: 600px) {
        font-size: 1.25rem;
        text-align: center;
        margin: 3.25rem 0 2rem;
    }
`;

const WorkTogetherContainer = styled.div`
    background-color: ${({ theme }) => theme.blueDark};
    color: white;
    padding: 1.875rem;
    margin: 1.25rem 0 0;
    align-items: center;
    font-family: 'Montserrat', sans-serif;

    p {
        margin: 0;
    }
    p a {
        color: inherit;
    }
    .together {
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
        @media (max-width: 600px) {
            width: 100%;
            flex-direction: column;
            text-align: center;
            margin-bottom: 0.625rem;
        }
    }
    .together h2 {
        width: 27rem;
        margin: 0;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.313rem;
        line-height: 1.563rem;
        font-weight: 300;
        @media (max-width: 600px) {
            width: 100%;
            text-align: center;
            margin-bottom: 0.625rem;
        }
    }
    .together a {
        margin-left: auto;
        margin-right: 0;
        white-space: nowrap;
        padding-left: 4.063rem;
        padding-right: 4.063rem;
        @media (max-width: 600px) {
            margin-right: auto;
            align-self: center;
        }
    }
`;

const AuthorsContainer = styled.div`
    margin-top: 0;
    display: flex;
    flex-direction: column;
    > :first-child {
        margin-top: 0;
    }
    > :last-child {
        margin-bottom: 0;
    }
`;

const BlogTemplate = ({ data, locale, t }) => {
    const { rawPost } = data;

    const siteUrl = data.site.siteMetadata.siteUrl;
    const team = data.team.edges.reduce(
        (acc, { node: { frontmatter } }) => ({
            ...acc,
            [frontmatter.name]: frontmatter,
        }),
        {},
    );

    const post = {
        ...omit(rawPost, ['frontmatter']),
        ...rawPost.frontmatter,
        authors: rawPost.frontmatter.authors.map(author => team[author]),
    };

    let socialCoverImage = null;
    if (post.cover_image) {
        socialCoverImage = post.cover_image.childImageSharp
            ? post.cover_image.childImageSharp.fluid.src
            : post.cover_image.publicURL;
    } else if (post.thumbnail_image) {
        socialCoverImage = post.thumbnail_image.publicURL;
    }

    return (
        <div>
            <Helmet title={`${post.title}`}>
                <html lang={locale} prefix="og: https://ogp.me/ns#" />
                {post.canonical && (
                    <link rel="canonical" href={post.canonical} />
                )}
                <meta
                    name="description"
                    content={post.excerpt
                        .replace(/<(?:.|\n)*?>/gm, '')
                        .slice(0, 300)}
                />
                <meta
                    name="author"
                    content={post.authors
                        .map(({ fullname }) => fullname)
                        .join(' and ')}
                />
                <meta property="og:url" content={`${siteUrl}/${post.path}`} />
                <meta property="og:title" content={post.title} />
                <meta
                    property="og:description"
                    content={post.excerpt
                        .replace(/<(?:.|\n)*?>/gm, '')
                        .slice(0, 300)}
                />
                {socialCoverImage && (
                    <meta
                        name="image"
                        property="og:image"
                        content={`${siteUrl}${socialCoverImage}`}
                    />
                )}

                {socialCoverImage && (
                    <meta name="twitter:card" content="summary_large_image" />
                )}
                {socialCoverImage && (
                    <meta
                        name="twitter:image"
                        content={`${siteUrl}${socialCoverImage}`}
                    />
                )}
                {post.authors
                    .filter(({ twitter }) => !!twitter)
                    .map(({ twitter }) => (
                        <meta
                            name="twitter:creator"
                            content={`@${twitter}`}
                            key={twitter}
                        />
                    ))}
                {socialCoverImage && (
                    <link rel="preload" href={socialCoverImage} as="image" />
                )}
            </Helmet>
            <TopBar white role="presentation" />
            <header>
                <AppBar />
                {post.cover_image && <HeaderImage image={post.cover_image} />}
            </header>
            <div role="main" className="blog-post-page">
                <BlogContainer>
                    <BlogMaxWidthContainer width={720}>
                        <PostTitle>{post.title}</PostTitle>
                        <MetaData>
                            <Authorship
                                date={post.date}
                                authors={post.authors}
                                linkToAuthorBlogPages
                            />
                            <BlogTagList tags={post.tags} />
                        </MetaData>
                        <PostBody>
                            <MDXProvider components={ShortCodes}>
                                <MDXRenderer>{post.body}</MDXRenderer>
                            </MDXProvider>
                        </PostBody>
                        <SharePost post={post} />
                    </BlogMaxWidthContainer>
                </BlogContainer>

                <LazyLoad height={600} once offset={100}>
                    <MetaContainer>
                        <BlogMaxWidthContainer width={720}>
                            <SectionTitle>
                                {t('aboutAuthors', {
                                    smart_count: post.authors.length,
                                })}
                            </SectionTitle>
                            <AuthorsContainer>
                                {post.authors.map(author => (
                                    <AuthorCard
                                        key={author.name}
                                        author={author}
                                    />
                                ))}
                            </AuthorsContainer>
                            <WorkTogetherContainer>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: t('we_are_marmelab'),
                                    }}
                                />
                                <div className="together">
                                    <h2>{t('call_to_action')}</h2>
                                    <GhostLinkButton href="mailto:contact@marmelab.com?subject=Contact from the marmelab blog">
                                        {t('aboutus.contact_us')}
                                    </GhostLinkButton>
                                </div>
                            </WorkTogetherContainer>
                            <SectionTitle>{t('discoverMore')}</SectionTitle>
                            <Disqus />
                        </BlogMaxWidthContainer>
                    </MetaContainer>
                </LazyLoad>
            </div>
            <Footer />
            {/* Newsletter form */}
            <script
                dangerouslySetInnerHTML={{
                    __html: `
window.LOCALE = 'en';
window.EMAIL_INVALID_MESSAGE = window.SMS_INVALID_MESSAGE = "The information you have provided is not valid. Please check the field format and try again.";
window.REQUIRED_ERROR_MESSAGE = "This field must be completed. ";
window.GENERIC_INVALID_MESSAGE = "The information you have provided is not valid. Please check the field format and try again.";

var AUTOHIDE = Boolean(0);
                `,
                }}
            ></script>
        </div>
    );
};

BlogTemplate.propTypes = {
    data: PropTypes.any,
    locale: PropTypes.string,
    t: PropTypes.func,
};

export default withSetLocale('en')(translate()(BlogTemplate));
